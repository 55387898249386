<template>
  <chip class="project-status" :class="getStatusClass()">
    {{ status }}
  </chip>
</template>

<script>
import { projectStatus } from "@/enums";

export default {
  components: {
    Chip: () => import("@/components/GlobalComponents/Chip")
  },
  mixins: [],
  props: {
    status: {
      type: String,
      default: ""
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    getStatusClass() {
      switch (this.status) {
        case projectStatus.NEW_LAUNCHED:
          return "new-launched";
        case projectStatus.UNDER_CONSTRUCTION:
          return "under-construction";
        case projectStatus.PARTIALLY_COMPLETED:
          return "partially-completed";
        case projectStatus.COMPLETED:
          return "completed";
        case projectStatus.COMING_SOON:
          return "coming-soon";
        case projectStatus.CLOSE:
          return "closed";
        default:
          break;
      }
    }
  }
};
</script>

<style lang="scss">
.project-status {
  text-align: center;
  white-space: nowrap;

  @mixin projectStatus($class, $bg-color, $color) {
    &.#{$class} {
      background: $bg-color;
      color: $color;
    }
  }

  @include projectStatus("new-launched", $color-main, white);
  @include projectStatus("under-construction", $color-warn, white);
  @include projectStatus("coming-soon", $color-accent2, white);
  @include projectStatus("partially-completed", $color-accent, white);
  @include projectStatus("completed", $color-success, white);
  @include projectStatus("closed", #ddd, #757575);
}
</style>
